@import "variables.css";

.footer__section {
  background-color: var(--dark-color-shade);
  max-height: 8rem;
  text-align: center;
  padding: 4.8rem;
}

.footer__content {
  text-transform: capitalize;
  color: var(--primary-color);
  font-size: 1.4rem;
}
