.footer__section {
  background-color: var(--dark-color-shade);
  text-align: center;
  max-height: 8rem;
  padding: 4.8rem;
}

.footer__content {
  text-transform: capitalize;
  color: var(--primary-color);
  font-size: 1.4rem;
}

/*# sourceMappingURL=index.5ac1740f.css.map */
